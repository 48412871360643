import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "p",
        { staticStyle: { "font-size": "1.3rem", color: "var(--dark)" } },
        [_c("b", [_vm._v(_vm._s(_vm.$t("company_revenue")))])]
      ),
      _c(
        VForm,
        {
          ref: "form",
          staticClass: "ma-6",
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.update_preferences()
            },
          },
        },
        [
          _c(
            VRadioGroup,
            {
              attrs: { center: "" },
              model: {
                value: _vm.Profile.CompanyRevenue,
                callback: function ($$v) {
                  _vm.$set(_vm.Profile, "CompanyRevenue", $$v)
                },
                expression: "Profile.CompanyRevenue",
              },
            },
            [
              _c(VRadio, {
                attrs: { value: 0 },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("span", { staticStyle: { color: "var(--dark)" } }, [
                          _vm._v(_vm._s(_vm.$t("0_revenue"))),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(VRadio, {
                attrs: { value: 1 },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("span", { staticStyle: { color: "var(--dark)" } }, [
                          _vm._v(_vm._s(_vm.$t("1mil"))),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(VRadio, {
                attrs: { value: 2 },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("span", { staticStyle: { color: "var(--dark)" } }, [
                          _vm._v(_vm._s(_vm.$t("5mil"))),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "p",
            { staticStyle: { "font-size": "1.3rem", color: "var(--dark)" } },
            [_c("b", [_vm._v(_vm._s(_vm.$t("industry_sector")))])]
          ),
          _c(
            VRow,
            { staticClass: "mt-2", attrs: { align: "center" } },
            [
              _vm._l(_vm.sectorOptions, function (c, i) {
                return _c(
                  VCol,
                  {
                    key: i,
                    staticClass: "my-0 py-0",
                    attrs: { cols: "12", md: "6" },
                  },
                  [
                    _c(VCheckbox, {
                      staticClass: "my-0 py-0",
                      attrs: { disabled: c.Disabled },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { color: "var(--dark)" } },
                                  [_vm._v(_vm._s(_vm.$t(c.Text)))]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: c.Marked,
                        callback: function ($$v) {
                          _vm.$set(c, "Marked", $$v)
                        },
                        expression: "c.Marked",
                      },
                    }),
                  ],
                  1
                )
              }),
              _c(
                VCol,
                { staticClass: "my-0 py-0", attrs: { cols: "12", md: "12" } },
                [
                  _c(VCheckbox, {
                    staticClass: "my-0 py-0 mx-auto text-center",
                    on: { change: _vm.noPreferenceSectorsCheckbox_changed },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              { staticStyle: { color: "var(--dark)" } },
                              [_vm._v(_vm._s(_vm.$t("no_preference")))]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.noPreferenceSector,
                      callback: function ($$v) {
                        _vm.noPreferenceSector = $$v
                      },
                      expression: "noPreferenceSector",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "p",
            { staticStyle: { "font-size": "1.3rem", color: "var(--dark)" } },
            [_c("b", [_vm._v(_vm._s(_vm.$t("invest_intended_question")))])]
          ),
          _c(
            VRadioGroup,
            {
              attrs: { center: "" },
              model: {
                value: _vm.Profile.InvestIntended,
                callback: function ($$v) {
                  _vm.$set(_vm.Profile, "InvestIntended", $$v)
                },
                expression: "Profile.InvestIntended",
              },
            },
            [
              _c(VRadio, {
                attrs: { value: 0 },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("span", { staticStyle: { color: "var(--dark)" } }, [
                          _vm._v(_vm._s(_vm.$t("up_to_50k"))),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(VRadio, {
                attrs: { value: 1 },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("span", { staticStyle: { color: "var(--dark)" } }, [
                          _vm._v(_vm._s(_vm.$t("up_to_100k"))),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(VRadio, {
                attrs: { value: 2 },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("span", { staticStyle: { color: "var(--dark)" } }, [
                          _vm._v(_vm._s(_vm.$t("up_to_500k"))),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(VRadio, {
                attrs: { value: 3 },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("span", { staticStyle: { color: "var(--dark)" } }, [
                          _vm._v(_vm._s(_vm.$t("up_to_1mil"))),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(VRadio, {
                attrs: { value: 4 },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("span", { staticStyle: { color: "var(--dark)" } }, [
                          _vm._v(_vm._s(_vm.$t("above_1mil"))),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-right mt-6" },
            [
              _c(
                VBtn,
                {
                  staticClass: "mr-0",
                  attrs: {
                    color: "primary",
                    type: "submit",
                    loading: _vm.loading,
                  },
                },
                [_vm._v(_vm._s(_vm.$t("update_preferences")))]
              ),
            ],
            1
          ),
          _vm.alert_msg != null
            ? _c(
                VAlert,
                {
                  staticClass: "ma-2",
                  attrs: { type: _vm.success ? "success" : "error" },
                },
                [_vm._v(" " + _vm._s(_vm.$t(_vm.alert_msg)) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }